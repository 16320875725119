import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Layouts
import DefaultLayout from './Default';
import BlankLayout from './Blank';

const Layout = ({ pages, children }) => {
  const [currentPath, setCurrentPath] = useState('/');
  const { pathname } = useLocation();

  useEffect(() => {
    setCurrentPath(pathname);
  }, [pathname]);
  
  const getPageLayout = (currentPath) => {
    const { layout } = pages.find(({ path }) => {
      return path === currentPath
    }) || {};
  
    return layout;
  }

  // Page Not Found
  if (!getPageLayout(currentPath)) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="font-bold text-3xl">Page Not Found</p>
        <Link className="text-grey-500 mt-3" to="/">Back to Home</Link>
      </div>
    )
  }

  /**
   * 
   * Make a condition if the "currentPath" is equals to *name of your layout*
   * then return the Layout Component
   * 
   * Ex.
   * if (getPageLayout(currentPath) === 'test') return <TestLayout>{children}</TestLayout> 
   * 
   */

  // ===== ADD NEW LAYOUTS HERE ===== //
  if (getPageLayout(currentPath) === 'blank') return <BlankLayout>{ children }</BlankLayout>

  return <DefaultLayout>{children}</DefaultLayout>
}

export default Layout;
import { useState } from 'react';

// Components
import { DefaultNavigation, MobileNavigation } from '../components/Navigation';
import Footer from '../components/Footer';

const DefaultLayout = ({ children }) => {
  const [sidebarState, setSidebarState] = useState(false);
  const [isSidebarRendered, setisSidebarRendered] = useState(false);

  const toggleSidebar = (value) => {
    // if true show the sidebar
    if (value) {
      setisSidebarRendered(true);

      setTimeout(() => {
        setSidebarState(value);
      });

      return;
    }
    
    setSidebarState(false);
    
    // This will give time the sidebar closing animation time to finish
    setTimeout(() => {
      setisSidebarRendered(value);
    }, 500);
  }

  return (
    <>
      <div className='fixed bottom-0 z-20 flex justify-center w-full mb-4'>
        <div className='px-4 py-2 font-medium text-white rounded-lg bg-red'>
          Temporarily Closed
        </div>
      </div>
      {
        isSidebarRendered &&
          <MobileNavigation
            active={sidebarState}
            onClose={ () => toggleSidebar(false) }/>
      }

      <DefaultNavigation onToggleSidebar={() => toggleSidebar(true)}></DefaultNavigation>
      
      <div className="min-h-screen">
        {children}
      </div>
      
      <Footer></Footer>

      
    </>
  )
}

export default DefaultLayout;
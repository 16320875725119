import { Link, useLocation, useHistory } from 'react-router-dom';

import { Button } from '../index';

import classnames from 'classnames';

import MCBLogoDarkWithoutSun from '../../assets/images/MCBLogoDarkWithoutSun';

import './DefaultNavigation.css';

const DefaultNavigation = ({ onToggleSidebar }) => {

  const location = useLocation();
  const history = useHistory();

  const links = [
    {
      name: 'Home',
      path: '/',
    },
    {
      name: 'About',
      path: '/about',
    },
    {
      name: 'Shop',
      path: '/shop',
    }
  ]

  const toggleSidebar = () => {
    onToggleSidebar();
  }

  const historyPush = (to) => {
    history.push(to);
  }

  return (
    <div className="navbar w-full h-24 top-0 z-20 absolute flex items-center"> 
      <div className="container max-w-5xl mx-auto relative h-full flex items-center justify-between">

        {/* Sidebar Button */}
        <Button
          className="p-1 focus:outline-none rounded lg:hidden bg-transparent border-none sticky"
          type="icon"
          onClick={ toggleSidebar }
        >
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            className="h-8 w-8"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.4 9a1.8 1.8 0 011.8-1.8h21.6a1.8 1.8 0 110 3.6H7.2A1.8 1.8 0 015.4 9zm0 9a1.8 1.8 0 011.8-1.8h21.6a1.8 1.8 0 010 3.6H7.2A1.8 1.8 0 015.4 18zm0 9a1.8 1.8 0 011.8-1.8h21.6a1.8 1.8 0 010 3.6H7.2A1.8 1.8 0 015.4 27z"
              fill="#434343"
            />
          </svg>
        </Button>

        <ul className="hidden lg:flex">
          {
            links.map(({ name, path }) => (
              <li className="mr-10" key={name}>
                <Link className={`font-medium text-tiny transition py-2 px-4 rounded-xl ${classnames({
                  'hover:bg-grey-700 bg-grey-700 text-white ': location.pathname === path,
                  'hover:bg-grey-200': location.pathname !== path,
                })}`} to={path}>{ name }</Link>
              </li>
            ))
          }
            
        </ul>

        <Link to="/" className="logo absolute w-36 hidden lg:block">
          <MCBLogoDarkWithoutSun></MCBLogoDarkWithoutSun>  
        </Link>

        <ul className="hidden lg:flex items-center">
          <li className="ml-2">
            <Button
              className="border-none font-medium bg-transparent hover:bg-grey-200 focus:ring-0"
              onClick={ () => historyPush('/')}>
              Create an account
            </Button>
          </li>
          <li className="ml-2">
            <Button
              className="font-medium bg-white"
              onClick={ () => historyPush('/')}>
              Sign In
            </Button>
          </li>
        </ul>
        

      </div>
    </div>  
  );
}

export default DefaultNavigation;
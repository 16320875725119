import { Link } from 'react-router-dom';

import MCBLogoDark from '../../assets/images/MCBLogoDark';

const Footer = () => {
  return (
    <div className="footer">
      <div className="flex justify-center mb-20 sm:mb-16">
        <div className="w-40 h-auto">
          <MCBLogoDark></MCBLogoDark>
        </div>
      </div>

      <ul className="text-center grid grid-cols-1 gap-8 sm:grid-cols-4 max-w-md mx-auto">
        <li className="font-medium text-grey-600">
          <Link to="/">Home</Link>
        </li>
        <li className="font-medium text-grey-600">
          <Link to="/about">About</Link>
        </li>
        <li className="font-medium text-grey-600">
          <Link to="/shop">Shop</Link>
        </li>
        <li className="font-medium text-grey-600">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>

      <ul className="text-center grid grid-cols-1 gap-8 sm:grid-cols-4 max-w-4xl mx-auto mt-12">
        <li className="font-medium text-grey-600">
          <p>Payment Methods</p>
        </li>
        <li className="font-medium text-grey-600">
          <p>Privacy Policy</p>
        </li>
        <li className="font-medium text-grey-600">
          <p>Return and Refund Policy</p>
        </li>
        <li className="font-medium text-grey-600">
          <p>Shipping Policy</p>
        </li>
      </ul>

      <div className="h-8 bg-grey-200 flex items-center justify-center mt-20 sm:mt-16">
        <p className="text-sm font-medium">© 2021 Maharlika Coffee Beans </p>
      </div>
    </div>
  )
}

export default Footer;
import RouterView from '../router';

function App() {
  return (
    <div className="App">
      <div id="popup-root"></div>

      {/* Router View */}
      <RouterView></RouterView>

    </div>
  );
}

export default App;

import { lazy } from 'react'
import Router from './Router';

const Home = lazy(() => import('../pages/Home'));
const About = lazy(() => import('../pages/About'));
const Shop = lazy(() => import('../pages/Shop'));
// const Login = lazy(() => import('../pages/Login'));
// const Register = lazy(() => import('../pages/Register'));
// const RequestPassword = lazy(() => import('../pages/RequestPassword'));
// const ChangePassword = lazy(() => import('../pages/ChangePassword'));
// const AccountConfirmation = lazy(() => import('../pages/AccountConfirmation'));
const CoffeeRequest = lazy(() => import('../pages/CoffeeRequest'));
const Contact = lazy(() => import('../pages/Contact'));

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
  },
  {
    name: 'About',
    path: '/about',
    component: About,
  },
  {
    name: 'Shop',
    path: '/shop',
    component: Shop,
  },
  {
    name: 'Contact',
    path: '/contact',
    component: Contact,
  },
  // {
  //   name: 'Login',
  //   path: '/login',
  //   component: Login,
  // },
  // {
  //   name: 'Register',
  //   path: '/register',
  //   component: Register,
  // },
  // {
  //   name: 'RequestPassword',
  //   path: '/request-password',
  //   component: RequestPassword,
  // },
  // {
  //   name: 'ChangePassword',
  //   path: '/change-password',
  //   component: ChangePassword,
  // },
  // {
  //   name: 'AccountConfirmation',
  //   path: '/account-confirmation',
  //   component: AccountConfirmation,
  // },
  {
    name: 'CoffeeRequest',
    path: '/coffee-request',
    component: CoffeeRequest,
    layout: 'blank'
  }
];

const RouterView = () => <Router routes={routes}></Router>

export default RouterView;

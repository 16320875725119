import PropTypes from 'prop-types';
import classnames from "classnames";

import './Button.css';
import classNames from 'classnames';


const buttonThemeClasses = theme =>
  classnames({
    'bg-white border border-grey-300 hover:bg-grey-100 focus:ring-grey-300': theme === 'default',
    'bg-primary hover:bg-primary-light focus:ring-primary-dark': theme === 'primary',
    'text-grey-100 bg-grey-700 hover:bg-grey-600 focus:ring-grey': theme === 'dark',
  });

const buttonTypeClasses = type =>
  classnames({
    'py-3 px-5': type === 'button',
    'p-2.5': type === 'icon',
    'p-0 border-none focus:ring-transparent bg-transparent': type === 'link',
  });

const buttonClasses = (theme, type) => `
  button rounded-xl focus:outline-none focus:ring-2 transition leading-none
  ${buttonThemeClasses(theme)} ${buttonTypeClasses(type)}
`;

const Button = ({ type, theme, children, className, isSubmit, loading, disabled, ...args }) => {
  return (
    <button type={isSubmit ? 'submit' : 'button'} disabled={loading || disabled} className={`${buttonClasses(theme, type)} ${className} ${classNames({
      'bg-grey-200 hover:bg-grey-200 focus:ring-0 border-none flex items-center justify-center cursor-not-allowed': loading || disabled
    })}`} {...args}>
      {
        loading ? <svg className="animate-spin h-5 w-5 text-grey-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg> : children
      }
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'link', 'icon']),
  theme: PropTypes.string,
  isSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  type: 'button',
  theme: 'default',
  isSubmit: false,
  loading: false,
  disabled: false,
};

export default Button;
import { useState, createRef, useEffect, forwardRef } from "react";

import { Button } from "..";

import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Textfield.css'

const Textfield = forwardRef((props, ref) => {
  const {
    className,
    placeholder,
    state,
    size,
    type,
    disabled,
    solo,
    defaultValue,
    prefix,
    suffix,
    onChange,
    onFocus,
    onBlur,
    ...args
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isPasswordShowed, showPassword] = useState(false);
  const inputContainterRef = createRef();

  const focusOnInput = () => {
    const elements = [...inputContainterRef.current.children]
    
    const inputElement = elements.find((el) => el.type === 'text' || el.type === 'password')

    inputElement.focus()
  }

  const handleInputChange = e => {
    setInputValue(e.target.value)
    onChange(e);
  }

  const handleInputFocus = e => {
    setIsFocused(true)
    onFocus(e);
  }

  const handleInputBlur = e => {
    setIsFocused(false)
    onBlur(e);
  }

  useEffect(() => {
    setInputValue(defaultValue)
  }, [defaultValue])

  return (
    <div className="textfield my-2">
      <div className={`
        cursor-text flex items-center py-1 px-3 bg-white border border-grey-200 overflow-hidden rounded-xl transition
        ${classNames({
          'h-11': size === 'sm',
          'h-12': size === 'md',
          'ring-1': isFocused,
          'border-1': state,
          'bg-grey-200 cursor-not-allowed': disabled,
          'ring-primary-light border-primary': isFocused && !state,
          'ring-red border-red': state && state.type === 'error',
          'ring-green border-green': state && state.type === 'success',
          'ring-orange border-orange': state && state.type === 'warning',
          'ring-blue border-blue': state && state.type === 'info',
          'pr-1': type === 'password' && inputValue !== ''
        })}
      `} onClick={focusOnInput}>
        {/* Prefix */}
        {prefix ? <> {prefix} </> : null}
        
        <div
          ref={inputContainterRef}
          onClick={focusOnInput}
          className="w-full h-full flex items-center relative overflow-hidden">
          
          {/* Placeholder */}
          <span className={`
            absolute flex select-none text-grey-500 transition-all
            ${classNames({
              'hidden': solo && inputValue !== '',
              'transform -translate-y-2 text-xs font-medium': (!solo && isFocused) || (!solo && inputValue !== ''),
            })}
          `}>{placeholder}</span>
          
          {/* Input Field */}
          <input
            ref={ref}
            className={`
              focus:outline-none bg-transparent font-medium w-full mt-auto -mb-0.5
              ${classNames({
                'mt-0 mb-0': solo,
                'select-none cursor-not-allowed': disabled,
              })}
              ${className}
            `}
            disabled={disabled}
            type={ type === 'password' && !isPasswordShowed ? 'password' : 'text'}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            value={inputValue}
            {...args}/>
        </div>
    
        {/* Suffix */}
        {suffix ? <> {suffix} </> : null}
          
        {/* Show Password Icon */}
        { type === 'password' && inputValue !== '' ? (
          <Button
            className="rounded-full h-full w-auto bg-transparent border-none focus:ring-0 py-0"
            type="icon"
            onClick={() => showPassword(!isPasswordShowed)}>
              
              {
                !isPasswordShowed ?
                <EyeIcon className="h-5 w-5"></EyeIcon> :
                <EyeOffIcon className="h-5 w-5"></EyeOffIcon>
              }
                
            </Button>
          ) : null }
      </div>
      
      {/* State Message */}
      { state && state.message ? 
        <div className={`
          flex justify-start
          ${classNames({
            'text-red': state.type === 'error',
            'text-green': state.type === 'success',
            'text-orange': state.type === 'warning',
            'text-blue': state.type === 'info',
          })}
        `}>
          <span className="text-sm m-1 ml-2">{ state.message }</span>
        </div>
      : null}
    </div>
  )
});

Textfield.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password']),
  size: PropTypes.oneOf(['sm', 'md']),
  defaultValue: PropTypes.string,
  state: PropTypes.object,
  disabled: PropTypes.bool,
  solo: PropTypes.bool,
  suffix: PropTypes.element,
  prefix: PropTypes.element,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

Textfield.defaultProps = {
  placeholder: '',
  type: 'text',
  size: 'md',
  state: null,
  defaultValue: '',
  disabled: false,
  solo: false,
  suffix: null,
  prefix: null,
  onChange: () => {},
  onFocus: () => { },
  onBlur: () => { },
};


export default Textfield; 
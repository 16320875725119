import { Suspense } from 'react';

import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import Layout from '../layouts/Layout';

const configPageLayouts = (routes) => {
  return routes.map(({ name, path, layout }) => {
    return {
      name,
      path,
      layout: layout ? layout : 'default'
    }
  });
}

const RouterView = ({ routes }) => {
  const location = useLocation();

  return (
    <Layout pages={configPageLayouts(routes)}>
      <Suspense fallback={<div></div>}>
      
        <Switch location={location}>
          {
            routes.map((route, index) => 
              <Route
                path={route.path}
                exact={route.path === '/'}
                key={route.name ? route.name : index}
                component={route.component} />
            )
          }
        </Switch>
      </Suspense>
    </Layout>
  )
}

export default RouterView;
import PropTypes from 'prop-types';

import './ProductsFeatured.css';

import NoImageAvailable from '../../assets/images/no-image-available.png';

const ProductsFeatured = ({ data, className, onClick }) => {
  return (
    <div className={`featured-products bg-white cursor-pointer rounded-lg shadow-md hover:shadow-xl transition ${className}`} onClick={onClick}>
      {/* Image */}
      <div className="p-2.5">
        <div className="product-image-container relative flex overflow-hidden rounded-lg">
          <img className="inset-0 w-full h-full object-cover object-center" src={ data.imageUrl || NoImageAvailable} alt="Default" />
        </div>
      </div>

      {/* Details */}
      <div className="p-2 truncate text-center">
        {/* Coffee Type */}
        <h3 className="font-semibold text-lg leading-none truncate">{data.coffee.type}</h3>
        {/* Coffee Origin */}
        <p className="mt-1 md:mt-0 truncate text-grey-600">{ data.coffee.origin }</p>
      </div>
    </div>
  )
}

ProductsFeatured.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func
};

ProductsFeatured.defaultProps = {
  data: {
    coffee: {
      type: '',
      origin: ''
    },
    imageUrl: ''
  },
  className: '',
  onClick: () => {},
};

export default ProductsFeatured;
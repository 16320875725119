import { createPortal } from 'react-dom';
import { Button } from '../../components';

import PropTypes from 'prop-types';

const Popup = props => {
  const {
    className,
    icon,
    title,
    description,
    actionTitle,
    actionFunction,
    onClose,
    isActive
  } = props;

  const popupRoot = document.getElementById('popup-root');

  const successIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14 text-green-dark" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
    </svg>
  );

  const errorIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14 text-red-dark" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
    </svg>
  )

  const warningIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14 text-orange-dark" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
    </svg>
  )

  const infoIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14 text-blue-dark" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
    </svg>
  )

  const getIcon = () => {
    switch (icon) {
      case 'success': {
        return successIcon;
      }
      case 'error': {
        return errorIcon;
      }
        case 'warning': {
        return warningIcon;
      }
        case 'info': {
        return infoIcon;
      }
      default: {
        return new Error('Invalid Icon Name')
      }
    }
  }

  const popupElement = (
    <>
      <div className="h-screen w-screen absolute bg-grey-800 opacity-20 z-40"></div>
      <div className={`
        z-50 absolute h-screen w-screen backdrop-filter backdrop-blur-sm transition-all duration-500 flex justify-center items-center animate-popupIn
        ${className}
      `}>
        <div className="w-96 bg-white rounded-3xl p-6 m-4">
          {/* Close Icon */}
          <div className="w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-grey-300 hover:text-grey-500 cursor-pointer ml-auto"
              viewBox="0 0 20 20"
              fill="currentColor" onClick={onClose}>
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>

          {/* State Icon */}
          <div className="flex justify-center mt-2">
            { getIcon() }
          </div>

          {/* Title */}
          <h1 className="font-semibold text-lg text-grey-700 text-center mt-4">{ title }</h1>

          {/* Description */}
          <p className="font-medium text-grey-600 text-tiny text-center">{ description }</p>

          {/* Action Button */}
          <Button className="w-full border-none bg-grey-100 text-tiny font-semibold mt-16" onClick={actionFunction}>{ actionTitle }</Button>
        </div>
      </div>
    </>
  );

  if (!isActive) return null;

  return createPortal(popupElement, popupRoot);
}

Popup.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  title: PropTypes.string,
  description: PropTypes.string,
  actionTitle: PropTypes.string,
  actionFunction: PropTypes.func,
  onClose: PropTypes.func,
  isActive: PropTypes.bool,
}

Popup.defaultProps = {
  className: '',
  icon: 'success',
  title: 'Popup',
  description: 'This is the pop up description',
  actionTitle: 'Done',
  actionFunction: () => { },
  onClose: () => { },
  isActive: false,
}

export default Popup;
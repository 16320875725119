import { useState, useEffect, createRef } from 'react';

import { Button } from '..';

import { PlusIcon, MinusSmIcon } from '@heroicons/react/solid';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CounterInput.css';

const CounterInput = props => {
  const {
    min,
    max,
    size,
    inputSize,
    defaultValue,
    state,
    disabled,
    onChange,
    onFocus,
    onBlur,
    ...args
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);
  const [isButtonClicking, setButtonClicking] = useState(false);

  const inputContainterRef = createRef();
  
  const focusOnInput = () => {
    if (disabled) return;

    const elements = [...inputContainterRef.current.children]
    
    const inputElement = elements.find((el) => el.type === 'number')

    inputElement.focus()
  }

  const handleOnChange = (value) => {
    onChange(value)
  }

  const increment = (e) => {
    if (inputValue === max) return;

    setInputValue(inputValue + size)
  }

  const decrement = (e) => {
    if (inputValue === min) return;

    setInputValue(inputValue - size)
  }

  const handleInputFocus = (e) => {
    setIsFocused(true);
    onFocus(e)
  }

  const handleInputBlur = (e) => {
    if (isButtonClicking) return;
    
    setIsFocused(false)
    onBlur(e)
  }

  useEffect(() => {
    handleOnChange(inputValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])
  
  useEffect(() => {
    if (min > defaultValue) setInputValue(min)
    if (max < defaultValue) setInputValue(max)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div
        className={` w-auto
          flex items-center p-1 bg-white border border-grey-200 overflow-hidden rounded-xl transition
          ${classNames({
            'h-11': inputSize === 'sm',
            'h-12': inputSize === 'md',
            'ring-1': isFocused,
            'border-1': state,
            'bg-grey-200 cursor-not-allowed': disabled,
            'ring-primary-light border-primary': isFocused && !state,
            'ring-red border-red': state && state.type === 'error',
            'ring-green border-green': state && state.type === 'success',
            'ring-orange border-orange': state && state.type === 'warning',
            'ring-blue border-blue': state && state.type === 'info',
          })}
        `}
        onClick={focusOnInput}
        ref={inputContainterRef}>

        <Button
          className="h-full w-auto py-0 bg-transparent border-none focus:ring-0"
          type="icon"
          onClick={decrement}
          onMouseDown={() => setButtonClicking(true)}
          onMouseUp={() => setButtonClicking(false)}
          onTouchStart={() => setButtonClicking(true)}
          onTouchEnd={() => setButtonClicking(false)}
          disabled={disabled}>
          <MinusSmIcon className="h-6 w-6 text-grey-600"></MinusSmIcon>
        </Button>

        <input
          type="number"
          className={`
            focus:outline-none text-center w-full bg-transparent font-semibold text-md flex items-center text-gray-700 cursor-pointer
            ${classNames({
              'cursor-not-allowed': disabled
            })}
          `}
          name="custom-input-number"
          readOnly
          value={inputValue}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          disabled={disabled}
          {...args}></input>
        
        <Button
          className="h-full w-auto py-0 bg-transparent border-none focus:ring-0"
          type="icon"
          onClick={increment}
          onMouseDown={() => setButtonClicking(true)}
          onMouseUp={() => setButtonClicking(false)}
          onTouchStart={() => setButtonClicking(true)}
          onTouchEnd={() => setButtonClicking(false)}
          disabled={disabled}>
          <PlusIcon className="h-6 w-6 text-grey-600"></PlusIcon>
        </Button>
      </div>
      
      {/* State Message */}
      { state && state.message ? 
        <div className={`
          ${classNames({
            'text-red': state.type === 'error',
            'text-green': state.type === 'success',
            'text-orange': state.type === 'warning',
            'text-blue': state.type === 'info',
          })}
        `}>
          <p className="text-sm m-1 ml-2">{ state.message }</p>
        </div>
      : null}
    </div>
  )
}

CounterInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  size: PropTypes.number,
  defaultValue: PropTypes.number,
  state: PropTypes.object,
  disabled: PropTypes.bool,
  inputSize: PropTypes.oneOf(['sm', 'md']),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

CounterInput.defaultProps = {
  min: -9999999,
  max: 9999999,
  size: 1,
  defaultValue: 0,
  state: null,
  disabled: false,
  inputSize: 'md',
  onFocus: () => { },
  onBlur: () => { },
  onChange: () => { }
}
export default CounterInput;
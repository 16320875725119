import { Fragment, useState, useEffect } from "react";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import PropTypes from 'prop-types';
import classNames from 'classnames';

const Select = props => {
  const {
    placeholder,
    items,
    size,
    state,
    disabled,
    solo,
    onSelect,
    onFocus,
    onBlur,
    defaultValue,
  } = props;

  const [inputValue, setInputValue] = useState({});
  const [isFocused, setIsFocused] = useState(false);

  const handleOnSelect = (e, value) => {
    setInputValue(value)
    onSelect(value, e);
  }

  const handleInputFocus = (e) => {
    setIsFocused(true)
    onFocus(e)
  }

  const handleInputBlur = (e) => {
    setIsFocused(false)
    onBlur(e)
  }

  useEffect(() => {
    if (defaultValue !== '' && items) {
      const val = items.find(({ value }) => value === defaultValue);
      if (!val) return;
      setInputValue(val)
    }

    if (defaultValue === '') setInputValue(defaultValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  useEffect(() => {
    if (items.lenght > 0) {
      items.map((item) => {
        if (item.default) {
          setInputValue(item)
        }
        return item;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Menu as="div" className="w-full relative my-2">
      <Menu.Button
        disabled={disabled}
        className="focus:outline-none w-full"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}>
        
        <div className={`
          relative flex items-center py-1 px-3 bg-white border border-grey-200 overflow-hidden rounded-xl transition
          ${classNames({
            'h-11': size === 'sm',
            'h-12': size === 'md',
            'ring-1': isFocused,
            'border-1': state,
            'cursor-pointer': !disabled,
            'bg-grey-200 cursor-not-allowed': disabled,
            'ring-primary-light border-primary': isFocused && !state,
            'ring-red border-red': state && state.type === 'error',
            'ring-green border-green': state && state.type === 'success',
            'ring-orange border-orange': state && state.type === 'warning',
            'ring-blue border-blue': state && state.type === 'info',
          })}
        `}>
          
          <span className={`
            absolute flex select-none text-grey-500 transition-all
            ${classNames({
              'hidden': solo && inputValue.name !== undefined,
              'transform -translate-y-2 text-xs font-medium': (!solo && inputValue.name !== undefined),
            })}
          `}>{placeholder}</span>
          
          <p className={`
            focus:outline-none bg-transparent font-medium w-full mt-auto -mb-0.5 text-left
            ${classNames({
              'mt-0 mb-0': solo,
            })}
          `}>
            {inputValue.name !== undefined ? inputValue.name : null}
          </p>

          <ChevronDownIcon className={`
            h-6 w-6
            ${classNames({
              'text-grey-500': disabled
            })}
          `}></ChevronDownIcon>
        </div>
      </Menu.Button>

      {
        items.length > 0 ? (
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-full z-10 origin-top bg-white divide-y divide-gray-100 rounded-lg shadow-lg ring-1 ring-grey-400 ring-opacity-5 focus:outline-none">
              <div className="p-1">
                {
                  items.map((item, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <button
                          type="button"
                          className="group flex rounded-md items-center w-full px-2 py-2 text-grey-600 hover:bg-grey-100 font-medium focus:outline-none focus:ring-2 ring-grey-300" 
                          onClick={(e) => handleOnSelect(e, item)}>
                          { item.name }
                        </button>
                      )}
                    </Menu.Item>
                  ))               
                }
              </div>
            </Menu.Items>
          </Transition>
        ) : null
      }

      {/* State Message */}
      { state && state.message ? 
        <div className={`
          ${classNames({
            'text-red': state.type === 'error',
            'text-green': state.type === 'success',
            'text-orange': state.type === 'warning',
            'text-blue': state.type === 'info',
          })}
        `}>
          <p className="text-sm m-1 ml-2">{ state.message }</p>
        </div>
      : null}
    </Menu>
  )
}

Select.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.array,
  size: PropTypes.string,
  state: PropTypes.object,
  disabled: PropTypes.bool,
  solo: PropTypes.bool,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.string
};

Select.defaultProps = {
  placeholder: '',
  items: [],
  size: 'md',
  state: null,
  disabled: false,
  solo: false,
  onSelect: () => { },
  onFocus: () => { },
  onBlur: () => { },
  defaultValue: '',
};

export default Select;
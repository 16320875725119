import { Link, useLocation } from 'react-router-dom';

import { XIcon } from '@heroicons/react/solid';

import classnames from 'classnames';

const MobileNavigation = ({ active, onClose }) => {
  const location = useLocation();

  const links = [
    {
      name: 'Home',
      path: '/'
    },
    {
      name: 'About',
      path: '/about'
    },
    {
      name: 'Shop',
      path: '/shop'
    },
    {
      name: 'Contact',
      path: '/contact'
    },
  ];

  const closeSidebarFromOutside = (e) => {
    const { id } = e.target;

    if (id !== 'sidebar-container') return;

    onClose();
  }

  const closeSidebar = (e) => {
    onClose();
  }

  return (
    <div
      className={
        `w-screen h-screen fixed z-30 backdrop-filter backdrop-blur-md transition-all opacity-0 duration-500
        ${classnames({
          'opacity-100': active
        })}`
      }
      onClick={closeSidebarFromOutside}
      id="sidebar-container">
      
      <aside className={
        `block bg-grey-100 w-72 h-full shadow-lg transform origin-left ease-in-out transition-all duration-500 ${classnames({
        '-translate-x-full': !active,
        'translate-x-0': active
        })}`}
        id="sidebar">

        {/* Sidebar Header */}
        <div className="flex items-center justify-between text-grey-700">

          {/* Menu Title */}
          <h1 className="font-black text-lg ml-6">MENU</h1>

          {/* Close Button */}
          <button className="p-5 focus:outline-none" onClick={closeSidebar}>
            <XIcon className="h-8 w-8"></XIcon>
          </button>
        </div>

        {/* Sidebar Links */}
        <ul className="py-4 px-4 text-grey-700">
          {
            links.map(({ name, path }) => 
              <li className="py-2" key={name}>
                <Link className={
                  `inline-flex w-full py-3 px-3 font-medium rounded-md ${classnames({
                    'bg-grey-200': location.pathname === path, 
                  })}`
                } to={path} >
                  {name}
                </Link>
              </li>
            )
          }
        </ul>

      </aside>
      
    </div>
  )
}

export default MobileNavigation;
import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';

// import { SaleorProvider } from "@saleor/sdk";

import './assets/css/index.css';

import App from './app/index.js';

// const config = { apiUrl: "https://store-api-dev.maharlikacoffeebeans.com/graphql/", channel: "" };
// const apolloConfig = {
//   /* 
//     Optional custom Apollo client config.
//     Here you may append custom Apollo cache, links or the whole client. 
//     You may also use import { createSaleorCache, createSaleorClient, createSaleorLinks } from "@saleor/sdk" to create semi-custom implementation of Apollo.
//   */
// };


ReactDOM.render(
  <React.StrictMode>
    <Router>
      {/* <SaleorProvider config={config} apolloConfig={apolloConfig}>
        
      </SaleorProvider> */}

      <App></App>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
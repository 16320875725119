import { useState, useEffect, createRef } from "react";

import PropTypes from 'prop-types';
import classNames from 'classnames';

const CurrencyField = props => {
  const {
    className,
    defaultValue,
    size,
    state,
    disabled,
    onChange,
    onBlur,
    onFocus,
    ...args
  } = props;

  const [inputValue, setInputValue] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);
  const inputContainterRef = createRef();

  const focusOnInput = () => {
    const elements = [...inputContainterRef.current.children]
    
    const inputElement = elements.find((el) => el.type === 'number')

    inputElement.focus()
  }

  const handleInputFocus = e => {
    setIsFocused(true)
    onFocus(e);
  }

  const handleInputBlur = e => {
    setIsFocused(false);

    // setInputValue(Number.parseFloat(e.target.value).toFixed(2));

    // if (!e.target.value) {
    //   setInputValue(Number.parseFloat(defaultValue).toFixed(2))
    // }

    onBlur(e);
  }

  const handleOnChange = e => {
    setInputValue(e.target.value)
    onChange(e);
  }

  useEffect(() => {
    setInputValue(defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  return (
    <div className="my-2">
       <div className={`
        cursor-text relative flex items-center py-1 px-3 bg-white border border-grey-200 overflow-hidden rounded-xl transition
        ${classNames({
          'h-11': size === 'sm',
          'h-12': size === 'md',
          'ring-1': isFocused,
          'border-1': state,
          'bg-grey-200 cursor-not-allowed': disabled,
          'ring-primary-light border-primary': isFocused && !state,
          'ring-red border-red': state && state.type === 'error',
          'ring-green border-green': state && state.type === 'success',
          'ring-orange border-orange': state && state.type === 'warning',
          'ring-blue border-blue': state && state.type === 'info',
        })}
      `}
        onClick={focusOnInput}
        ref={inputContainterRef}>
        <p className="font-medium">₱</p>
        <input
          className={`
            focus:outline-none bg-transparent font-medium w-full text-right
            ${classNames({
            'select-none cursor-not-allowed': disabled,
          })}
            ${className}
          `}
          disabled={disabled}
          type="number"
          value={inputValue}
          onChange={handleOnChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur} {...args}/>
      </div>

      {/* State Message */}
      { state && state.message ? 
        <div className={`
          flex justify-start
          ${classNames({
            'text-red': state.type === 'error',
            'text-green': state.type === 'success',
            'text-orange': state.type === 'warning',
            'text-blue': state.type === 'info',
          })}
        `}>
          <span className="text-sm m-1 ml-2">{ state.message }</span>
        </div>
      : null}
    </div>
  )
}

CurrencyField.propTypes = {
  defaultValue: PropTypes.number,
  size: PropTypes.string,
  state: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

CurrencyField.defaultProps = {
  defaultValue: 0.00,
  size: 'md',
  state: null,
  disabled: false,
  onChange: () => { },
  onBlur: () => { },
  onFocus: () => {},
}

export default CurrencyField;